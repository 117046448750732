import React from "react";
import Post from "../../components/post";

export default function Post2() {
  return (
    <Post i={1}>
      <p>
        A lot of people reflect on their year before it ends. On the last day of
        the year, I saw lots of posts of what people learned and accomplished in
        2018.
      </p>
      <p>
        Not me. I prefer to do my reflecting a little bit late. Since my
        birthday comes early January, it gives me a buffer to reflect on my
        year. It was an interesting year: I left a job I was emotionally
        attached to, I decided to go back to school, I joined a company I had
        admired for years, and I learned a lot in between. I write this as I've
        just been admitted to grad school, so the past year finally feels
        completed.
      </p>
      <p>
        <b>I learned when enough is enough</b>
      </p>
      <p>
        If you're reading this, you probably know where I used to work. There's
        no need to list all the reasons I decided to leave. While there had been
        times that I thought about leaving, I was loyal to the company. There
        was still interesting work to be done and leadership opportunities.
      </p>
      <p>
        One day, I realized it just wasn't working for me anymore. It was as
        simple as that. There was nothing that would make me stay. I gave myself
        a 2 month timeline to find a job, and I would move on.
      </p>
      <p>
        <b>I learned to not let people take the floor when you have it</b>
      </p>
      <p>
        This has happened a few times. During a talk or presentation, an
        audience member will either ask an a) slightly related question about a
        slightly related topic (“Do you know what X is?”) or b) completely
        related general question that challenges the entire point being made (“I
        thought you're not supposed to use Y.”).
      </p>
      <p>
        To be clear, I welcome questions. I usually give the benefit of the
        doubt because most people with questions have really great questions.
        However, I've done this enough now to know if it walks and quacks like a
        troll, it's a troll.
      </p>
      <p>
        I have still been caught off guard from time to time. I will have a
        moment that makes me think I misunderstood the question or wasn't clear
        on a point. After a talk this year, I was mad at myself for not
        answering a question more quickly and moving on to the next. I let that
        person have the floor for more time than he deserved — I won't let that
        happen again.
      </p>
      <p>
        <b>I relearned how to learn</b>
      </p>
      <p>
        In May, I joined Heroku (
        <span role="img" aria-label="tada">
          🎉
        </span>
        ). Two months later, I started taking online classes at NYU that I
        needed to be admitted into their grad program. After months of allowing
        my brain to run in autopilot, it was an adjustment. Somehow, I managed
        to balance everything I needed to learn for both without sacrificing too
        much of my personal time.
      </p>
      <p>
        I replaced some old activities for new ones, and I slightly changed my
        lifestyle to be in the right mindset. It's hard to summarize what worked
        and what didn't work, but here are a few notable points:
      </p>
      <ul>
        <li>
          waking up earlier (I used to be a night owl, but I found out I get a
          lot done in the mornings)
        </li>
        <li>
          allowing my brain to have scheduled and limited idle time for watching
          TV or some activity with low ROI
        </li>
        <li>
          replacing music, novels and podcasts with school and Heroku-related
          articles, books and videos
        </li>
        <li>
          getting enough sleep (this is relatively new to me because I used to
          be of the opinion that sleep was time taken away from enjoying life)
        </li>
        <li>
          meditating and yoga (also new because I used to think these were
          synonyms for sleeping)
        </li>
      </ul>
      <p>
        I'm privileged to have enough time to do all of these things in a 7-day
        week. My work days became slightly longer with the added school work,
        and my brain was clear enough to be able to absorb the information I
        needed. I used a{" "}
        <a
          href="https://en.wikipedia.org/wiki/Kanban"
          target="_blank"
          rel="noreferrer"
        >
          Kanban
        </a>{" "}
        board to manage my work, so I could deprioritize topics I didn't need or
        would never get to.
      </p>
      <p>
        While none of these are directly related to the learning process, I
        would not have been able to succeed (ish) without these adjustments.
      </p>
      <hr></hr>
      <p>
        While a part of me wants to list the bucket list items I was able to
        check off, I don't think that's where the real value of the past year
        lies. 2018 was great, but I'm more excited for where it will take me to
        in 2019.
      </p>
    </Post>
  );
}
